.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.report-class {
  margin-block: 0.4rem;
  height: 100%;
}

.ProseMirror {
  min-height: 100px;
}

.dx-datagrid-borders > .dx-datagrid-headers {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dx-datagrid-borders > .dx-datagrid-total-footer,
.dx-datagrid-borders > .dx-datagrid-rowsview {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dx-datagrid-pager {
  border-top: none;
}

.dx-datagrid > .dx-datagrid-rowsview {
  background-color: transparent;
}

.custom-cell {
  vertical-align: middle !important;
}

.custom-cell[role="columnheader"] {
  font-weight: bold;
}

.custom-pivot-grid thead {
  font-weight: bold;
}

.custom-pivot-grid {
  border: 1px solid #ccc;
  border-radius: 10px !important;
}

.custom-pivot-grid .dx-area-description-cell {
  border-top-left-radius: 10px;
}

.pivot-grid-dark {
  border: 1px solid #fff !important;
  background-color: transparent;
}

.pivot-grid-dark span {
  color: #fff !important;
}
